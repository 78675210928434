// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: "Montserrat Alternates", sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono",
  "Monaco", monospace !default;

/// Copy text color
/// @type Color
$text-color: rgb(34, 34, 34) !default;

/// Main brand color
/// @type Color
$brand-color: #ff1f17 !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;

/// Orange
/// @type Color
$secondary-color: #ff7f0c !default;

/// Error color
/// @type Color
$error-color: #ff1f17 !default;

/// Success color
/// @type Color
$success-color: #00d000 !default;

/// Container's maximum width
/// @type Length
$max-width: 1180px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  "small": 767px,
  "medium": 992px,
  "large": 1200px,
) !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: "/assets/" !default;

/// Spacing value
/// @type Length
$spacing: 8px;

//transition value
$transition: all 0.4s ease;

//lateral padding
$lateral-padding-default: $spacing * 3;
$lateral-padding-small: $spacing * 8;
$lateral-padding-medium: $spacing * 15;
$lateral-padding-large: $spacing * 20;

$columns: 12;
$image-border-radius: $spacing;
$grid-gap-default: $spacing * 2;
$grid-cols-default: 2;
$grid-cols-small: 4;