// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $brand-color;
  text-decoration: none;

  @include on-event {
    color: $brand-color;
    text-decoration: none;
  }
}

.text-center {
  text-align: center;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.overflow-auto {
  overflow: auto;
}

.mt-1 {
  margin-top: $spacing / 2;
}

.mt-2 {
  margin-top: $spacing;
}

.mt-3 {
  margin-top: $spacing * 1.5;
}

.mt-4 {
  margin-top: $spacing * 2;
}

.mt-5 {
  margin-top: $spacing * 2.5;
}

.mr-1 {
  margin-right: $spacing / 2;
}

.mr-2 {
  margin-right: $spacing;
}

.mr-3 {
  margin-right: $spacing * 1.5;
}

.mr-4 {
  margin-right: $spacing * 2;
}

.mr-5 {
  margin-right: $spacing * 2.5;
}

.ml-2 {
  margin-left: $spacing;
}

.margin-auto {
  margin: auto;
}

.p-0 {
  padding: 0;
}

.py-5 {
  padding-top: $spacing * 5;
  padding-bottom: $spacing * 5;
}

.flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}
