.cart__user-info {
  &-main-container {
    display: flex;
    justify-content: space-between;
  }

  &-guest {
    &-container {
      width: 50%;
      border-left: 1px solid $mid-grey;
      padding: $spacing * 3;
    }
    &-title{
      color: $brand-color;
    }
    &-modal{
      &-container{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($color: $mid-grey, $alpha: .5);
      }
      &-form{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $light-grey;
        padding: $spacing * 4;
        max-width: 400px;
        max-height: 100vh;
        overflow: auto;
        border-radius: $image-border-radius;

        & > *{
          margin-bottom: $spacing * 2;

          &:last-child{
            margin-bottom: 0;
          }
        }

        textarea {
          min-height: 42px;
        }
      }
      &-close-btn{
        cursor: pointer;
        position: absolute;
        top: $spacing;
        right: $spacing * 2;
        font-size: 1.25rem;
      }
    }
  }
}
