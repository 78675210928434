.form__login,
.form__register,
.form__recover,
.form__edit {
  max-width: 400px;
  margin: auto;
  padding: $spacing * 4;
  border-radius: $image-border-radius;
  box-shadow: 0 $spacing $spacing * 2 rgba($color: $dark-grey, $alpha: 0.5);

  & > * {
    margin-bottom: $spacing * 2;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.form__title {
  position: relative;

  &-back-btn {
    position: absolute;
    left: 0;
    top: 0;
    color: $mid-grey;

    &:hover,
    &:active {
      color: $dark-grey;
    }
  }
}
