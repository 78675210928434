.imageDisplay__container{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background-color: rgba($color: #000, $alpha: .7);

  .imageDisplay__closeIcon {
    position: absolute;
    top: 24px;
    right: 42px;
    color: #fff;
    font-size: 2rem;
    cursor: pointer;
  }

  .imageDisplay__image {
    width: 90%;
    max-width: 800px;
  }
}