.loader__main-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background-color: rgba($color: $light-grey, $alpha: .7);
}
