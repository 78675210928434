.dropdown__grid-container {
  grid-area: buttons;
  position: relative;
  display: inline-block;

  @include respond-to("small"){
    display: none;
  }
}

.dropdown__container {
  width: 110px;

  &:hover .dropdown__content, &:active .dropdown__content  {
    display: block;
  }
}

.dropdown__dropbtn {
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown__content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  & a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;

    &:hover {
      background-color: #f1f1f1;
    }
  }
}
