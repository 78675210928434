.grid__container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px 1fr auto;
  grid-template-areas:
    "navbar  "
    "content "
    "footer  ";
  min-height: 100vh;
  background-color: $light-grey;

  .grid__padding {
    padding: $spacing * 2 $lateral-padding-default;
  }

  .grid__navbar {
    grid-area: navbar;
  }

  .grid__content {
    grid-area: content;
  }

  .grid__footer {
    grid-area: footer;
    background-color: $dark-grey;
  }
}

@include respond-to("small") {
  .grid__container {
    grid-template-rows: 64px 1fr auto;

    .grid__padding {
      padding: $spacing * 2 $lateral-padding-small;
    }
    .grid__footer {
      max-height: 200px;
    }
  }
}

@include respond-to("medium") {
  .grid__container .grid__padding {
    padding: $spacing * 2 $lateral-padding-medium;
  }
}

@include respond-to("large") {
  .grid__container .grid__padding {
    padding: $spacing * 2 $lateral-padding-large;
  }
}
